import TextField from "@material-ui/core/TextField";
import React from "react";
import "./TextInput.scss";

export default (props) => {
  return (
    <TextField
      {...props}
      error={!!props.error}
      id="standard-basic"
      color="primary"
      fullWidth={true}
      helperText={props.error}
    />
  );
};
