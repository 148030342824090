import React from "react";
import SellingTableDesktop from "./SellingTableDesktop";
import SellingTableMobile from "./SellingTableMobile";

const SellingTable = () => (
  <>
    <SellingTableDesktop />
    <SellingTableMobile />
  </>
);

export default SellingTable;
