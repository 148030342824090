import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";

import NavbarWhite from "../common/Navbar/NavbarWhite";

import Valuation from "./Valuation";
import Recipient from "./Recipient";

const HomeValue = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <NavbarWhite />
      <Switch>
        <Route path={`${path}/recipient`} component={Recipient} />
        <Route path={`${path}/valuation`} component={Valuation} />
      </Switch>
    </>
  );
};

export default HomeValue;
