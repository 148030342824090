import React from "react";
import "./OptionButton.scss";
import createActiveClass from "../../../utils/createActiveClass";

const OptionButton = (props) => {
  return (
    <button
      className={`button option ${createActiveClass(
        props.selected,
        props.option
      )}`}
      onClick={() => props.onClick(props.option)}
    >
      {props.option}
    </button>
  );
};

export default OptionButton;
