import React from "react";

const FormHeader = props => {
  return (
    <div className="form-header">
      <h2>{props.text}</h2>
    </div>
  );
};

export default FormHeader;
