import React, { useContext } from "react";
import { withRouter } from "react-router";
import "./PropertyInfo.scss";

import BackButton from "../../common/BackButton";
import FormInputContainer from "../FormInputContainer";
import FormHeader from "../FormHeader";
import Context from "../../../Context";
import Stepper from "../Stepper";
// Blue Icons
import singeFamilyColoredIcon from "../../../assets/house_colored_icon.svg";
import condoColoredIcon from "../../../assets/condo_colored_icon.svg";
import townHouseColoredIcon from "../../../assets/townhouse_colored_icon.svg";
import landColoredIcon from "../../../assets/land_colored_icon.svg";
import mobileHomeColoredIcon from "../../../assets/mobile_home_colored_icon.svg";
import otherColoredIcon from "../../../assets/other_colored_icon.svg";
// White Icons
import singeFamilyWhiteIcon from "../../../assets/house_white_icon.svg";
import condoWhiteIcon from "../../../assets/condo_white_icon.svg";
import townHouseWhiteIcon from "../../../assets/townhouse_white_icon.svg";
import landWhiteIcon from "../../../assets/land_white_icon.svg";
import mobileHomeWhiteIcon from "../../../assets/mobile_home_white_icon.svg";
import otherWhiteIcon from "../../../assets/other_white_icon.svg";
import PropTypeOption from "./PropTypeOption";

const PropertyType = (props) => {
  const context = useContext(Context);

  const handlePropertyTypeSelection = (propertyType) => {
    context.updatePropertyType(propertyType);
    props.history.push("/form/selling/1");
  };

  return (
    <Context.Consumer>
      {({ propertyType }) => (
        <>
          <BackButton />
          <Stepper />
          <FormHeader text="What type of property is it?" />
          <FormInputContainer>
            <div id="property-type-container">
              <div className="property-type-row">
                <PropTypeOption
                  onClick={() => handlePropertyTypeSelection("single-family")}
                  propertyType={propertyType}
                  name={"single"}
                  whiteIcon={singeFamilyWhiteIcon}
                  coloredIcon={singeFamilyColoredIcon}
                  title={"Single Family"}
                />
                <PropTypeOption
                  onClick={() => handlePropertyTypeSelection("condo")}
                  propertyType={propertyType}
                  name={"condo"}
                  whiteIcon={condoWhiteIcon}
                  coloredIcon={condoColoredIcon}
                  title={"Condominium"}
                />
                <PropTypeOption
                  onClick={() => handlePropertyTypeSelection("condo")}
                  propertyType={propertyType}
                  name={"townhouse"}
                  whiteIcon={townHouseWhiteIcon}
                  coloredIcon={townHouseColoredIcon}
                  title={"Townhouse"}
                />
              </div>

              <div className="property-type-row">
                <PropTypeOption
                  onClick={() => handlePropertyTypeSelection("single-family")}
                  propertyType={propertyType}
                  name={"mobileHome"}
                  whiteIcon={mobileHomeWhiteIcon}
                  coloredIcon={mobileHomeColoredIcon}
                  title={"Mobile Home"}
                />
                <PropTypeOption
                  onClick={() => handlePropertyTypeSelection("land")}
                  propertyType={propertyType}
                  name={"land"}
                  whiteIcon={landWhiteIcon}
                  coloredIcon={landColoredIcon}
                  title={"Land"}
                />
                <PropTypeOption
                  onClick={() => handlePropertyTypeSelection("other")}
                  propertyType={propertyType}
                  name={"other"}
                  whiteIcon={otherWhiteIcon}
                  coloredIcon={otherColoredIcon}
                  title={"Other"}
                />
              </div>
            </div>
          </FormInputContainer>
        </>
      )}
    </Context.Consumer>
  );
};

export default withRouter(PropertyType);
