import React, { useContext } from "react";
import "../Form.scss";
import * as Yup from "yup";
import { Formik } from "formik";
import { withRouter } from "react-router";

import FormHeader from "../FormHeader";
import FormInput from "../../common/FormInput";
import BackButton from "../../common/BackButton";
import Context from "../../../Context";
import Stepper from "../Stepper";
import FormContainer from "../FormContainer";
import GreenButton from "../../common/GreenButton";

const AddressSchema = Yup.object().shape({
  address: Yup.string()
    .min(2, "Must have at least 2 characters")
    .max(30, "Must have at most 30 characters")
    .required("Required"),
  city: Yup.string()
    .min(2, "Must have at least 2 characters")
    .max(30, "Must have at most 30 characters")
    .required("Required"),
  zip: Yup.string()
    .min(2, "Must have at least 2 characters")
    .max(30, "Must have at most 30 characters")
    .required("Required"),
  state: Yup.string()
    .min(2, "Must have at least 2 characters")
    .max(2, "Must have at most 2 characters")
    .required("Required"),
});

const EnterAddressManual = (props) => {
  const context = useContext(Context);

  const handleSubmit = (values) => {
    values.fullAddress = `${values.address}, ${values.city} ${values.state} ${values.zip}`;
    context.updateAddress(values);
    props.history.push("/form/property-info/1");
  };

  return (
    <Context.Consumer>
      {({ addressInfo }) => (
        <>
          <Stepper />
          <BackButton />
          <FormHeader text="Enter your address" />
          <FormContainer>
            <Formik
              initialValues={addressInfo}
              validationSchema={AddressSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, handleChange, values, errors }) => {
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="flex-column" id="enter-address-manual">
                      <div className="flex-item">
                        <span className="flex-3">
                          <FormInput
                            label="Street Address"
                            name="address"
                            onChange={handleChange}
                            value={values.address}
                            error={errors.address}
                          />
                        </span>
                        <span className="flex-1">
                          <FormInput
                            label="Apt. # (Optional)"
                            name="address2"
                            onChange={handleChange}
                            value={values.address2}
                          />
                        </span>
                      </div>
                      <div className="flex-item">
                        <span className="flex-3">
                          <FormInput
                            label="City"
                            name="city"
                            onChange={handleChange}
                            value={values.city}
                            error={errors.city}
                          />
                        </span>
                        <span className="flex-1">
                          <FormInput
                            label="Zip"
                            name="zip"
                            onChange={handleChange}
                            value={values.zip}
                            error={errors.zip}
                          />
                        </span>
                      </div>
                      <div className="flex-item">
                        <span className="flex-3">
                          <FormInput
                            label="State"
                            name="state"
                            onChange={handleChange}
                            value={values.state}
                            error={errors.state}
                          />
                        </span>

                        <span className="flex-1 address-submit">
                          <GreenButton
                            type="submit"
                            onClick={handleSubmit}
                            title="Submit"
                          />
                        </span>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </FormContainer>
        </>
      )}
    </Context.Consumer>
  );
};

export default withRouter(EnterAddressManual);
