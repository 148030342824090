const persons = [
  {
    name: "Benjamin	H.",
    city: "Lebanon",
    hoursAgo: 3,
  },
  {
    name: "Charlotte S.",
    city: "Lebanon",
    hoursAgo: 2,
  },
  {
    name: "Chad	J.",
    city: "Greenbrier",
    hoursAgo: 1,
  },
  {
    name: "Jeffrey W.",
    city: "Murfreesboro",
    hoursAgo: 2,
  },
  {
    name: "Deeana	Z.",
    city: "Madison",
    hoursAgo: 3,
  },
  {
    name: "Stephanie B.",
    city: "Spring Hill",
    hoursAgo: 2,
  },
  {
    name: "Joe S.",
    city: "Nashville",
    hoursAgo: 1,
  },
  {
    name: "Franklin	G.",
    city: "Murfreesboro",
    hoursAgo: 3,
  },
  {
    name: "Stanley O.",
    city: "Mt. Juliet",
    hoursAgo: 1,
  },
  {
    name: "Anita L.",
    city: "Nashville",
    hoursAgo: 2,
  },
  {
    name: "Madison S.",
    city: "Mt. Juliet",
    hoursAgo: 1,
  },
  {
    name: "James	B.",
    city: "Hendersonville",
    hoursAgo: 3,
  },
  {
    name: "Nicole S.",
    city: "Spring Hill",
    hoursAgo: 4,
  },
  {
    name: "Sherry	W.",
    city: "Nashville",
    hoursAgo: 1,
  },
  {
    name: "Merrick S.",
    city: "Franklin",
    hoursAgo: 2,
  },
  {
    name: "Robert B.",
    city: "Clarksville",
    hoursAgo: 3,
  },
];

export default persons;
