import { TextField } from "@material-ui/core";
import React, { forwardRef } from "react";
import PhoneInput from "react-phone-number-input";
import "./PhoneInput.scss";

const phoneInput = forwardRef((props, ref) => {
  return <TextField {...props} inputRef={ref} fullWidth name="phone" />;
});

export default (props) => {
  return (
    <PhoneInput
      className={"phone-input"}
      {...props}
      fullWidth={true}
      color="primary"
      id="standard-basic"
      error={!!props.error}
      helperText={props.error}
      value={props.value}
      inputComponent={phoneInput}
      country="US"
      defaultCountry="US"
    />
  );
};
