import React, { useState, useContext } from "react";
import Context from "../../../Context";
import axios from "axios";
import { baseUrl } from "../../../utils/baseUrl";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import SearchInput from "../../common/SearchInput/SearchInput";
import searchIconBlue from "../../../assets/search-icon-blue.svg";
import "./HomeIsWorth.scss";

const HomeIsWorth = (props) => {
  const [address, setAddress] = useState("");
  const [suggestions, setSuggesions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const context = useContext(Context);

  const handleGetEstimate = () => {
    props.history.push("/form/address/1");
  };

  const handleAddressInput = (e) => {
    setAddress(e.target.value);
    getAddressSuggestions(e.target.value);
  };

  const getAddressSuggestions = (addressInput) => {
    const params = {
      addressInput,
    };

    axios
      .get(`${baseUrl}/api/v1/address/suggestions`, { params })
      .then((res) => {
        handleDropdownOpen();
        setSuggesions(res.data);
      });
  };

  const handleDropdownOpen = () => {
    setDropdownOpen(true);
    document.addEventListener("click", handleDropdownClose);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
    document.removeEventListener("click", handleDropdownClose);
  };

  const handleSuggestionSelection = (suggestion) => {
    handleDropdownClose();
    setAddress(suggestion.address);
    handleGetPropertyDetails(suggestion.id);
  };

  const handleGetPropertyDetails = (id) => {
    const params = {
      id,
    };
    axios.get(`${baseUrl}/api/v1/address/details`, { params }).then((res) => {
      context.updateAddress({ ...res.data, address2: "" });
    });
  };

  const createSuggestions = (suggestions) =>
    suggestions.map((suggestion) => (
      <li
        key={suggestion.id}
        onClick={() => handleSuggestionSelection(suggestion)}
      >
        {suggestion.address}
      </li>
    ));

  return (
    <Context.Consumer>
      {({ addressInfo }) => (
        <section id="home-is-worth">
          {props.header}

          <div className="container home-worth-container">
            <div className="enter-address hiw-enter-address">
              <SearchInput
                titles={["Search\u00A0homes", "Get\u00A0estimate"]}
                placeholder="Your home address"
                buttonClassName="dark-blue"
                btnTitle="Search"
                icon={searchIconBlue}
                onChange={handleAddressInput}
                value={address}
                btnOnClick={handleGetEstimate}
                btnDisabled={!addressInfo.fullAddress}
              />
              {dropdownOpen && (
                <div
                  className="dropdown"
                  ref={(dropDown) => {
                    dropDown = dropDown;
                  }}
                >
                  <ul>{createSuggestions(suggestions)}</ul>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </Context.Consumer>
  );
};

export default withRouter(HomeIsWorth);
