import React from "react";
import "./FormWrapper.scss";

const FormWrapper = (props) => {
  return (
    <div className="form-input-container-wrap" style={props.style}>
      {props.children}
    </div>
  );
};

export default FormWrapper;
