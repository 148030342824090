import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/baseUrl";
import axios from "axios";
import Map from "./Map";
import Comp from "./Comp";
import { ScaleLoader } from "react-spinners";
import "./Comps.scss";

const Comps = ({ addressInfo, propertyType }) => {
  const [comps, setComps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const { lat, lng } = addressInfo;

  const params = {
    lat,
    lng,
    propertyType,
  };

  const getComps = async () => {
    setIsLoading(true);
    try {
      const {
        data: { properties },
      } = await axios.get(`${baseUrl}/api/v1/comps`, { params });
      setComps(properties);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const notFoundContent = (
    <div className="not-found">
      <p>
        Unfortunately we couldn't find any recent sales in your neighborhood.
      </p>
      <p>
        However, worry not! Just give us a call and we'll do this one by hand 🕵️‍♂️
      </p>
    </div>
  );

  useEffect(() => {
    if (lat !== 0 && lng !== 0 && propertyType) {
      getComps();
    }
  }, [addressInfo, propertyType]);

  return (
    <section id="recent-sales">
      <h2>Recent sales in your neighborhood.</h2>
      <div id="comps">
        <div id="comp-map">
          <Map lat={lat} lng={lng} comps={comps} selected={selected} />
        </div>
        <ul id="comp-list" onMouseLeave={() => setSelected(null)}>
          {isLoading && (
            <div className="comp-loader">
              <ScaleLoader color={"#F36966"} />
            </div>
          )}
          {!isLoading && !comps?.length && notFoundContent}
          {comps?.length
            ? comps.map((comp) => (
                <Comp key={comp.mlsId} comp={comp} setSelected={setSelected} />
              ))
            : null}
        </ul>
      </div>
    </section>
  );
};

export default Comps;
