import React from "react";
import {ThemeProvider} from "@material-ui/core/styles";
import MUITheme from "./MUITheme";

import Landing from "./components/Landing";
import Form from "./components/Form";
import HomeValue from "./components/HomeValue";
import Consultation from "./components/Consulation";
import Head from "./components/common/Head";
import isMobile from "./utils/isMobile";
import Drift from "react-driftjs";
import {Switch, Route} from "react-router";
import {BrowserRouter} from "react-router-dom";
import SocialProofWidget from "./components/SocialProofWidget";

import Provider from "./Provider";
const DRIFT_APP_ID = "wzb4rsyd6gzh";

function App() {
  return (
    <ThemeProvider theme={MUITheme}>
      <Provider>
        <BrowserRouter>
          <Head />
          {/* Hidding Drift widget for now */}
          {/* {!isMobile() && <Drift appId={DRIFT_APP_ID} style={{ left: 0 }} />} */}
          <div className="App">
            <Switch>
              <Route exact path="/" component={Landing} />

              <Route path="/form" component={Form} />

              <Route path="/report" component={HomeValue} />

              <Route exact path="/consultation" component={Consultation} />
            </Switch>
          </div>
          <SocialProofWidget />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
