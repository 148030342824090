import React, {useEffect} from "react";
import {Route, useRouteMatch, Switch} from "react-router-dom";
import "./Form.scss";

import NavbarTransparent from "../common/Navbar/NavbarTransparent";
import Address from "./Address";
import PropertyInfo from "./PropertyInfo";
import AdditionalDetails from "./AdditionalDetails";
import Selling from "./Selling";
import scrollToTop from "../../utils/scroolToTop";
import FormFooter from "./FormFooter";

const Form = () => {
  let {path} = useRouteMatch();

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <NavbarTransparent />
      <div id="form">
        <div id="form-content">
          <Switch>
            <Route path={`${path}/property-info`} component={PropertyInfo} />
            <Route path={`${path}/address`} component={Address} />
            <Route path={`${path}/selling`} component={Selling} />
            <Route
              path={`${path}/additional-details`}
              component={AdditionalDetails}
            />
          </Switch>
        </div>
        <FormFooter />
      </div>
    </>
  );
};

export default Form;
