import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import sliderImage1 from "../../../assets/data-slider-image-1.png";
import sliderImage2 from "../../../assets/data-slider-image-2.png";
import sliderImage3 from "../../../assets/data-slider-image-3.png";
import sliderImage4 from "../../../assets/data-slider-image-4.png";
import phoneImage from "../../../assets/phone-image.png";
import "./WhatYouGet.scss";

const WhatYouGet = () => {
  const [state, setState] = useState({ nav1: null, nav2: null });
  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    });
  }, []);

  const { nav1, nav2 } = state;

  return (
    <section id="data">
      <div className="data-container">
        <div className="data-half left">
          <div className="data-copy-container">
            <h3>What do I get with a home valuation?</h3>
            <Slider
              asNavFor={nav1}
              ref={(slider) => (slider2.current = slider)}
              slidesToShow={4}
              swipeToSlide={true}
              focusOnSelect={true}
              vertical={true}
              arrows={false}
              dots={false}
              autoplay={true}
              autoplaySpeed={3000}
            >
              <div>
                <h4 data-position="1">Receive multiple estimates.</h4>
              </div>
              <div>
                <h4 data-position="2">
                  See recently sold in your neighborhood.
                </h4>
              </div>
              <div>
                <h4 data-position="3">See how much you save.</h4>
              </div>
              <div>
                <h4 data-position="4">Connect with your advisor.</h4>
              </div>
            </Slider>
          </div>
        </div>
        <div className="data-half right">
          <div className="data-phone-image">
            <img src={phoneImage} alt="phone-image" />
          </div>
          <Slider
            asNavFor={nav2}
            ref={(slider) => (slider1.current = slider)}
            vertical={true}
            arrows={false}
            dots={false}
          >
            <div>
              <img src={sliderImage1} />
              <p>text</p>
            </div>
            <div>
              <img src={sliderImage2} />
              <p>text</p>
            </div>
            <div>
              <img src={sliderImage3} />
              <p>text</p>
            </div>
            <div className="last-slide">
              <img src={sliderImage4} />
              <p>text</p>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default WhatYouGet;
