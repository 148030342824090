import React from "react";
import {withRouter} from "react-router";
import "./Navbar.scss";
import logoBlack from "../../../assets/logo_black.svg";
import phoneDownIcon from "../../../assets/phone-icon.svg";

const NavbarWhite = () => {
  return (
    <div className="navbar navbar-white">
      <div className="container navbar-container">
        <a href="https://felixhomes.com/">
          <img className="logo" src={logoBlack} alt="logo-black" />
        </a>
      </div>
    </div>
  );
};

export default withRouter(NavbarWhite);
