import React from "react";
import { Route, useRouteMatch, Switch } from "react-router";
import PropertyType from "./PropertyType";
import HomeBuilt from "./HomeBuilt";
import HomeCondition from "./HomeCondition";

const PropertyInfo = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/1`} component={PropertyType} />
      <Route path={`${path}/2`} component={HomeBuilt} />
      <Route path={`${path}/3`} component={HomeCondition} />
    </Switch>
  );
};

export default PropertyInfo;
