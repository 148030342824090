import React, { useState } from "react";
import "./SearchInput.scss";
import searchIconMint from "../../../assets/search-icon-mint.svg";
import searchIconBlue from "../../../assets/search-icon-blue.svg";

const SearchInput = (props) => {
  const [selectedTab, selectTab] = useState(0);
  const { titles } = props;

  return (
    <div className={"searchInput"}>
      {props.tabs ? (
        <div className={"tabs"} style={props.tabsStyle}>
          <span
            className={selectedTab === 0 ? "selected-mint" : "not-selected"}
            onClick={() => selectTab(0)}
          >
            FIND A HOME
          </span>
          <span
            className={selectedTab === 1 ? "selected-blue" : "not-selected"}
            onClick={() => selectTab(1)}
          >
            HOME ESTIMATE
          </span>
        </div>
      ) : null}

      <div className={"input-wrapper"} style={props.wrapperStyle}>
        {props.icon ? <img src={props.icon} alt={"icon"} /> : null}
        {!props.mobile && !props.icon && props.tabs ? (
          <img
            src={selectedTab === 0 ? searchIconMint : searchIconBlue}
            alt={"icon"}
          />
        ) : null}
        <input
          className={selectedTab === 0 ? "border-mint" : "border-blue"}
          style={{
            ...props.inputStyle,
            ...(props.icon && { paddingLeft: 62 }),
            ...(!props.mobile &&
              !props.icon &&
              props.tabs && { paddingLeft: 62 }),
          }}
          type={props.type}
          placeholder={
            props.placeholder
              ? props.placeholder
              : selectedTab === 0
              ? "Enter address, city, zip"
              : "Enter your home address"
          }
          value={props.value}
          onChange={props.onChange}
        />
        <button
          style={props.buttonStyle}
          className={
            props.buttonClassName
              ? props.buttonClassName
              : selectedTab === 0
              ? "mint"
              : "blue"
          }
          onClick={props.btnOnClick}
          disabled={props.btnDisabled}
        >
          {props.btnTitle
            ? props.btnTitle
            : selectedTab === 0
            ? titles[0]
            : titles[1]}

        </button>
      </div>
    </div>
  );
};

// Exports
export default SearchInput;