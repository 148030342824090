import React from "react";
import { withRouter } from "react-router";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const NextButton = props => {
  const handleNextClick = () => {
    props.history.push(props.to);
  };
  return (
    <button className="button next" onClick={handleNextClick}>
      Next &nbsp;
      <ArrowRightAltIcon />
    </button>
  );
};

export default withRouter(NextButton);
