import createActiveClass from "../../../../utils/createActiveClass";
import React from "react";
import "./PropTypeOption.scss";

const PropTypeOption = (props) => {
  const { onClick, propertyType, whiteIcon, coloredIcon, title, name } = props;

  const determineActiveIcon = (activeType, name) => {
    return activeType === name ? true : false;
  };

  return (
    <div
      onClick={onClick}
      className={`property-type ${createActiveClass(propertyType, name)}`}
    >
      <img
        src={determineActiveIcon(propertyType, name) ? whiteIcon : coloredIcon}
        alt={`${title.toLowerCase()}-icon`}
      />
      <h4>{title}</h4>
    </div>
  );
};

export default PropTypeOption;
