import React, { useContext } from "react";
import { withRouter } from "react-router";
import "./Address.scss";

import BackButton from "../../common/BackButton";
import FormHeader from "../FormHeader";
import FormInputContainer from "../FormInputContainer";
import GoogleMap from "google-map-react";
import Context from "../../../Context";
import Stepper from "../Stepper";
import locationMarker from "../../../assets/location_marker.png";
import PenIcon from "../../../assets/pen-icon.svg";
import GreenButton from "../../common/GreenButton";

const ConfirmAddress = (props) => {
  const handleCompleteAddress = () => {
    useContext.updateAddressPosition(true);
  };

  const Marker = () => (
    <img className="marker" src={locationMarker} alt="marker" />
  );

  const handleEditAddressClick = () => {
    props.history.push("/form/address/3");
  };

  const createMapOptions = () => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      zoomControl: false,
      fullscreenControl: false,
      draggable: false,
    };
  };

  return (
    <Context.Consumer>
      {({ addressInfo }) => {
        // Check if we got an address in context
        const lat = addressInfo ? addressInfo.lat : 36.163738614114024;
        const lng = addressInfo ? addressInfo.lng : -86.8059630493164;
        return (
          <>
            <BackButton />
            <Stepper />
            <FormHeader text="Is this the correct address?" />
            <FormInputContainer>
              <div id="map-wrapper">
                <div id="map-container">
                  <GoogleMap
                    options={createMapOptions}
                    bootstrapURLKeys={{
                      key: "AIzaSyAck__kwf_lNX31lGietRyBADcJtx4fXUM",
                      language: "en",
                    }}
                    center={[lat, lng]}
                    zoom={12}
                  >
                    <Marker lat={lat} lng={lng} />
                  </GoogleMap>
                </div>
              </div>
              <div id="confirm-address">
                <span>
                  <p>
                    {addressInfo.address}, {addressInfo.address2}
                  </p>
                  <p>
                    {addressInfo.city}, {addressInfo.state}, {addressInfo.zip}
                  </p>
                </span>
                <span className="edit" onClick={handleEditAddressClick}>
                  <img src={PenIcon} alt={"pen-icon"} />
                  <p>Edit&nbsp;Address</p>
                </span>
              </div>
              <GreenButton
                onClick={handleCompleteAddress}
                to="/form/property-info/1"
                title={"Calculate my Home Value  →"}
                style={{ marginTop: 26 }}
              />
            </FormInputContainer>
          </>
        );
      }}
    </Context.Consumer>
  );
};

export default withRouter(ConfirmAddress);
