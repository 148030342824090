import React, {useContext} from "react";
import {withRouter} from "react-router";
import "./PropertyInfo.scss";

import BackButton from "../../common/BackButton";
import OptionButton from "../../common/OptionButton";
import FormWrapper from "../FormWrapper";
import Context from "../../../Context";
import FormHeader from "../FormHeader";
import Stepper from "../Stepper";

const HomeBuilt = (props) => {
  const context = useContext(Context);

  const handleOptionSelection = (propertyAge) => {
    context.updatePropertyAge(propertyAge);
    props.history.push("/form/selling/1");
  };

  return (
    <Context.Consumer>
      {({propertyAge}) => (
        <>
          <BackButton />
          <Stepper />
          <FormHeader text="Roughly, when was your home built?" />
          <FormWrapper>
            <OptionButton
              onClick={handleOptionSelection}
              option="2000s and Newer"
              selected={propertyAge}
            />
            <OptionButton
              onClick={handleOptionSelection}
              option="1970s"
              selected={propertyAge}
            />
            <OptionButton
              onClick={handleOptionSelection}
              option="1990s"
              selected={propertyAge}
            />
            <OptionButton
              onClick={handleOptionSelection}
              option="1960s"
              selected={propertyAge}
            />
            <OptionButton
              onClick={handleOptionSelection}
              option="1980s"
              selected={propertyAge}
            />
            <OptionButton
              onClick={handleOptionSelection}
              option="Before 1960"
              selected={propertyAge}
            />
          </FormWrapper>
        </>
      )}
    </Context.Consumer>
  );
};

export default withRouter(HomeBuilt);
