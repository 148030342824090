import React, {useContext} from "react";
import {withRouter} from "react-router";
import "./Selling.scss";

import BackButton from "../../common/BackButton";
import OptionButton from "../../common/OptionButton";
import Context from "../../../Context";
import FormHeader from "../FormHeader";
import Stepper from "../Stepper";
import FormWrapper from "../FormWrapper";

const SellingSoon = (props) => {
  const context = useContext(Context);

  const handleOptionSelection = (knowAboutfelixCommission) => {
    context.updateKnowAboutfelixCommission(knowAboutfelixCommission);
    props.history.push("/form/additional-details");
  };

  return (
    <Context.Consumer>
      {({knowAboutfelixCommission}) => (
        <>
          <BackButton />
          <Stepper />
          <FormHeader text="What was your reaction when you learned that Felix Homes only charges 1% to sell your home?" />
          <FormWrapper>
            <OptionButton
              option="Don't care."
              onClick={handleOptionSelection}
              selected={knowAboutfelixCommission}
            />
            <OptionButton
              option="That's so awesome!"
              onClick={handleOptionSelection}
              selected={knowAboutfelixCommission}
            />
          </FormWrapper>
        </>
      )}
    </Context.Consumer>
  );
};

export default withRouter(SellingSoon);
