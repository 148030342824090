import React from "react";
import "./SellingTable.scss";
import CheckIcon from "../../../assets/check-icon.svg";
import SellWhyHouse from "../../../assets/sell-why.svg";

const SellingTableMobile = () => {
  return (
    <div id={'pricing-sell-wrapper-mobile'} className={"pricing-sell-wrapper"}>
      <div className={"content-wrapper"}>
        <h3>WHY FELIX IS BETTER</h3>
        <h2>Selling with Felix just makes sense.</h2>

        <div className={"advantage-table"}>
          <div
            className={"row"}
            style={{ borderColor: "#FFD463", height: 20 }}
          />

          <div className={"row"}>
            <div className={"name-column"}>1% Listing Commission</div>
            <div className={"felix-column"}>
              <img src={CheckIcon} alt={"check-icon"} />
            </div>
          </div>
          <div className={"row"}>
            <div className={"name-column"}>
              Custom home
              <br />
              valuation analysis
            </div>
            <div className={"felix-column"}>
              <img src={CheckIcon} alt={"check-icon"} />
            </div>
          </div>
          <div className={"row"}>
            <div className={"name-column"}>
              Comprehensive home
              <br />
              marketing strategy
            </div>
            <div className={"felix-column"}>
              <img src={CheckIcon} alt={"check-icon"} />
            </div>
          </div>
          <div className={"row"}>
            <div className={"name-column"}>
              Professional photography <br />+ drone footage
            </div>
            <div className={"felix-column"}>
              <img src={CheckIcon} alt={"check-icon"} />
            </div>
          </div>
          <div className={"row"} style={{ border: "none" }}>
            <div className={"name-column"}>No hidden fees. Ever.</div>
            <div className={"felix-column"}>
              <img src={CheckIcon} alt={"check-icon"} />
            </div>
          </div>
        </div>
      </div>
      <img src={SellWhyHouse} alt={"sell-why-house"} />
    </div>
  );
};

export default SellingTableMobile;
