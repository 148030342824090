import React from "react";
import {Route, useRouteMatch, Switch} from "react-router";
import SellingSoon from "./SellingSoon";
import FelixCommission from "./FelixCommission";

const Selling = () => {
  let {path} = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/1`} component={SellingSoon} />
      <Route path={`${path}/2`} component={FelixCommission} />
    </Switch>
  );
};

export default Selling;
