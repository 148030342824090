import React from "react";
import Modal from "@material-ui/core/Modal";
import "./Modal.scss";

const SimpleModal = (props) => {
  const body = <div id={"modal-body"}>{props.children}</div>;

  return (
    <Modal hideBackdrop open={props.open} onClose={props.handleClose}>
      {body}
    </Modal>
  );
};

export default SimpleModal;
