import React from "react";
import "./Benefits.scss";

const Benefits = () => {
  return (
    <div className={"benefits-wrapper"}>
      <div className={"left"}>
        <h3>The benefits of getting an online home valuation.</h3>
      </div>
      <div className={"right"}>
        <div className={"content"}>
          <div>
            <div className={"step"}>
              <h2>01.</h2>
              <label>It may be worth more than you think</label>
              <span>
                Find out if you're sitting on an opportunity to upgrade,
                downsize, or cash in equity.
              </span>
            </div>
            <div className={"step"}>
              <h2>02.</h2>
              <label>Prepare for buyers playing hard ball</label>
              <span>
                Arm yourself with a more accurate home valuation, a fair price
                price, and a top agent's recommendation.
              </span>
            </div>
          </div>
          <div>
            <div className={"step"}>
              <h2>03.</h2>
              <label>Have better conversations with agents</label>
              <span>
                Bring your estimate to your first meeting and start the
                discussion on the right track.
              </span>
            </div>
            <div className={"step"}>
              <h2>04.</h2>
              <label>Sell whenever you’re ready</label>
              <span>
                Use our free comprehensive home valuation report and talk to a
                top agent to list at exactly the right time for you.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
