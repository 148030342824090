import React, { useContext } from "react";

import BackButton from "../../common/BackButton";
import FormInputContainer from "../FormInputContainer";
import Context from "../../../Context";
import FormHeader from "../FormHeader";
import NextButton from "../../common/NextButton";
import Stepper from "../Stepper";
import FormTextArea from "../../common/FormTextArea";
import GreenButton from "../../common/GreenButton";
import FormContainer from "../FormContainer";

const placeholder =
  "(Optional) Enter any additional details that might help us with your estimate.";

const AdditionalDetails = () => {
  const context = useContext(Context);

  const handleChange = (e) => {
    const { value } = e.target;
    context.updateAdditionalDetails(value);
  };

  return (
    <Context.Consumer>
      {({ additionalDetails }) => (
        <>
          <BackButton />
          <Stepper />
          <FormHeader text="Is there anything else you would like to tell us about your home?" />
          <FormContainer>
            <FormTextArea
              value={additionalDetails}
              onChange={handleChange}
              placeholder={placeholder}
              style={{ width: "100%" }}
            />
            <GreenButton
              to="/report/recipient"
              title={"Calculate my Home Value  →"}
              style={{ marginTop: 22 }}
            />
          </FormContainer>
        </>
      )}
    </Context.Consumer>
  );
};

export default AdditionalDetails;
