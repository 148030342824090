import React, {useContext} from "react";
import {withRouter} from "react-router";
import "./Selling.scss";

import BackButton from "../../common/BackButton";
import OptionButton from "../../common/OptionButton";
import Context from "../../../Context";
import FormHeader from "../FormHeader";
import Stepper from "../Stepper";
import FormWrapper from "../FormWrapper";

const SellingSoon = (props) => {
  const context = useContext(Context);

  const handleOptionSelection = (propertyAge) => {
    context.updatePropertyCondition(propertyAge);
    props.history.push("/form/selling/2");
  };

  return (
    <Context.Consumer>
      {({sellingValue}) => (
        <>
          <BackButton />
          <Stepper />
          <FormHeader text="What do you look for when selling your home?" />
          <FormWrapper>
            <OptionButton
              option="Highest Price"
              onClick={handleOptionSelection}
              selected={sellingValue}
            />
            <OptionButton
              option="Highest Savings"
              onClick={handleOptionSelection}
              selected={sellingValue}
            />
            <OptionButton
              option="Quickest Sale"
              onClick={handleOptionSelection}
              selected={sellingValue}
            />
            <OptionButton
              option="Experienced Agents"
              onClick={handleOptionSelection}
              selected={sellingValue}
            />
          </FormWrapper>
        </>
      )}
    </Context.Consumer>
  );
};

export default withRouter(SellingSoon);
