import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import "./HomeValue.scss";

import Context from "../../Context";
import Hero from "./Hero";
import Questions from "./Questions";
import ValueProps from "./ValueProps";
import RecentSales from "./RecentSales";
import { baseUrl } from "../../utils/baseUrl";
import Loader from "../common/Loader";
import Footer from "../Footer";
import TagManager from "react-gtm-module";

import { buildLeadSourceObj } from "../../utils/leadSourceCookie";
import SellingTable from "../common/SellingTable";

const HomeValue = (props) => {
  const context = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);

  const tagManagerArgs = {
    gtmId: "GTM-KR98RZZ",
    dataLayer: {
      event: "formSubmit",
      source: "hvr",
    },
  };

  // Async issue with how our fuctions get called and context gets updated
  const storeLead = (valuations, recipient) => {
    const leadSources = buildLeadSourceObj();

    const body = {
      ...context,
      recipient,
      valuations,
      ...leadSources,
    };
    axios.post(`${baseUrl}/api/v1/lead`, body).then((res) => {
      TagManager.initialize(tagManagerArgs);
    });
  };

  const getValuations = (recipient) => {
    if (context.addressInfo.address) {
      setIsLoading(true);
      const body = {
        ...context.addressInfo,
      };

      axios
        .post(`${baseUrl}/api/v1/valuation`, body)
        .then((res) => {
          const valuationInfo = res.data;

          context.updateValuations(valuationInfo);
          storeLead(valuationInfo, recipient);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getValuations(context.recipient);
  }, []);

  if (!context.recipient.email) {
    props.history.push("/report/recipient");
  }
  const homeValueContent = (
    <div id="valuations-content">
      <Hero valuations={context.valuations} addressInfo={context.addressInfo} />

      <RecentSales
        addressInfo={context.addressInfo}
        propertyType={context.propertyType}
      />

      <ValueProps />

      <SellingTable />

      <Questions />
      <Footer />
    </div>
  );
  const loadingContent = <Loader isLoading={isLoading} />;

  return <>{isLoading ? loadingContent : homeValueContent}</>;
};

export default withRouter(HomeValue);
