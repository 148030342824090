import React from "react";
import { withRouter } from "react-router";
import "./BlueButton.scss";

const BlueButton = (props) => {
  const { title, onClick, to, style } = props;

  const handleNextClick = () => {
    props.history.push(to);
  };

  return (
    <button
      className={"blue-button"}
      style={{
        ...style,
      }}
      onClick={onClick && !to ? onClick : handleNextClick}
    >
      {title}
    </button>
  );
};

export default withRouter(BlueButton);
