import React from "react";
import { withRouter } from "react-router";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const BackButton = props => {
  const handleBackClick = () => {
    props.history.goBack();
  };
  return (
    <button className="button back" onClick={handleBackClick}>
      <ArrowRightAltIcon style={{ transform: "rotate(180deg)" }} />
      &nbsp; &nbsp;Back
    </button>
  );
};

export default withRouter(BackButton);
