import React from "react";
import Loader from "../common/Loader";

import "./Consultation.scss";

const Consultation = props => {
  return (
    <div id="consultation">
      <Loader isLoading />
      <iframe
        title="calendly"
        className="calendly"
        src="https://calendly.com/felixhomes/seller-consultation"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </div>
  );
};

export default Consultation;
