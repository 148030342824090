import React from "react";
import "./common.scss";

const FormInput = props => {
  return (
    <div className="form-input-control">
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <input
        id={props.name}
        className="form-input"
        name={props.name}
        value={props.value}
        onChange={e => props.onChange(e)}
      />
      <span className="form-error">{props.error}</span>
    </div>
  );
};

export default FormInput;
