import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import googleReviewLogo from "../../../assets/google-reviews-white.png";
import NavbarWhite from "../../common/Navbar/NavbarWhite";
import Context from "../../../Context";
import axios from "axios";
import { baseUrl } from "../../../utils/baseUrl";
import searchIconBlue from "../../../assets/search-icon-blue.svg";
import SearchInput from "../../common/SearchInput/SearchInput";
import "./Hero.scss";

const Hero = (props) => {
  const [address, setAddress] = useState("");
  const [suggestions, setSuggesions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const context = useContext(Context);

  const handleGetEstimate = () => {
    props.history.push("/form/address/1");
  };

  const getAddressSuggestions = (addressInput) => {
    const params = {
      addressInput,
    };

    axios
      .get(`${baseUrl}/api/v1/address/suggestions`, { params })
      .then((res) => {
        handleDropdownOpen();
        setSuggesions(res.data);
      });
  };

  const handleAddressInput = (e) => {
    setAddress(e.target.value);
    getAddressSuggestions(e.target.value);
  };

  const handleDropdownOpen = () => {
    setDropdownOpen(true);
    document.addEventListener("click", handleDropdownClose);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
    document.removeEventListener("click", handleDropdownClose);
  };

  const handleSuggestionSelection = (suggestion) => {
    handleDropdownClose();
    setAddress(suggestion.address);
    handleGetPropertyDetails(suggestion.id);
  };

  const handleGetPropertyDetails = (id) => {
    const params = {
      id,
    };
    axios.get(`${baseUrl}/api/v1/address/details`, { params }).then((res) => {
      context.updateAddress({ ...res.data, address2: "" });
    });
  };

  const createSuggestions = (suggestions) =>
    suggestions.map((suggestion) => (
      <li
        key={suggestion.id}
        onClick={() => handleSuggestionSelection(suggestion)}
      >
        {suggestion.address}
      </li>
    ));

  useEffect(() => {
    if (context.addressInfo.fullAddress)
      setAddress(context.addressInfo.fullAddress);
  }, [context.addressInfo]);

  return (
    <Context.Consumer>
      {({ addressInfo }) => (
        <>
          <NavbarWhite />
          <>
            <div id="hero">
              <div className="landing-content container">
                <h1>Ready to sell your home?</h1>
                <p>Find out the value in 3 easy steps.</p>
                <div className="enter-address">
                  <SearchInput
                    titles={["Search\u00A0homes", "Get\u00A0estimate"]}
                    placeholder="Enter your home address"
                    buttonClassName="dark-blue"
                    btnTitle="Search"
                    icon={searchIconBlue}
                    onChange={handleAddressInput}
                    value={address}
                    btnOnClick={handleGetEstimate}
                    btnDisabled={!addressInfo.fullAddress}
                  />
                  {dropdownOpen && (
                    <div
                      className="dropdown"
                      ref={(dropDown) => {
                        dropDown = dropDown;
                      }}
                    >
                      <ul>{createSuggestions(suggestions)}</ul>
                    </div>
                  )}
                </div>

                <div className="rating">
                  <img src={googleReviewLogo} alt="google-reviews" />
                  4.8/5 by Felix Customers
                </div>
              </div>
            </div>
          </>
        </>
      )}
    </Context.Consumer>
  );
};

export default withRouter(Hero);
