import React from "react";
import "./ScheduleCall.scss";
import { withRouter } from "react-router";

const ScheduleCall = (props) => {
  const handleCtaClick = () => {
    props.history.push("/consultation");
  };
  return (
    <section id="schedule-call">
      <div className="container schedule-call-container">
        <h4>
          Questions? <br />
          Speak with a home valuation expert.
        </h4>
        <button onClick={handleCtaClick} className="button schedule-call-btn">
          Schedule a Call
        </button>
      </div>
    </section>
  );
};

export default withRouter(ScheduleCall);
