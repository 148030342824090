import React from "react";
import "./common.scss";

const FormTextArea = ({ value, onChange, placeholder, style }) => {
  return (
    <textarea
      onChange={onChange}
      value={value}
      className="form-text-field"
      placeholder={placeholder}
      style={style}
    />
  );
};

export default FormTextArea;
