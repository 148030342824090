import React from "react";
import { withRouter } from "react-router";
import "./GreenButton.scss";

const GreenButton = (props) => {
  const { title, onClick, to } = props;

  const handleNextClick = () => {
    props.history.push(to);
  };

  return (
    <button
      className={"green-button"}
      style={{
        ...props.style,
      }}
      onClick={onClick && !to ? onClick : handleNextClick}
    >
      {title}
    </button>
  );
};

export default withRouter(GreenButton);
