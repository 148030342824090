import React from "react";
import { Route, useRouteMatch, Switch } from "react-router";

import EnterAddressSuggest from "./EnterAddressSuggest";
import EnterAddressManual from "./EnterAddressManual";
import ConfirmAddress from "./ConfirmAddress";

const Address = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/1`} component={ConfirmAddress} />
      <Route path={`${path}/2`} component={EnterAddressManual} />
      <Route path={`${path}/3`} component={EnterAddressSuggest} />
    </Switch>
  );
};

export default Address;
