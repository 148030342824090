import React from "react";
import BlueButton from "../../common/BlueButton";
import { withRouter } from "react-router";
import "./ValueProps.scss";

const ValueProps = ({ header, history }) => {
  const handleCtaClick = () => {
    history.push("/consultation");
  };
  return (
    <section id="value-props">
      <div className="row-1">
        <div className="main-img">
          <img src="/images/valueprop-img.jpg" />
        </div>
        <div className="guide-content">
          <h2>
            Thinking about selling? Here's your{" "}
            <span className="with-line">first step.</span>
          </h2>
          <p>
            Don't even think about selling before booking your no-pressure
            consultation with one of our local experts.
          </p>
          <BlueButton
            style={{ maxWidth: 320 }}
            onClick={handleCtaClick}
            title="Book a consultation - It's free"
          />
        </div>
      </div>
      <div className="row-2">
        <div className="statistics">
          <h4>Recent Statistics</h4>
          <table cellpadding="0" cellspacing="0" className="statistics-table">
            <tr>
              <td>
                <div>
                  <small>Avg. Sales Price</small>
                  $482,994
                </div>
              </td>
              <td>
                <div>
                  <small>Avg. Price/SQFT</small>
                  $482,994
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <small>Avg. Days on Market</small>
                  87 Days
                </div>
              </td>
              <td>
                <div>
                  <small>Avg. % of List Price</small>
                  97.8%
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <small>New Listing</small>
                  875
                </div>
              </td>
              <td>
                <div>
                  <small>Closed Sales</small>
                  474
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="get-updates">
          <h2>
            Nashville by
            <span className="with-line">the numbers.</span>
          </h2>
          <p>
            Nashville is changing every day. Stay up to date on the latest
            market data so you can make the best decisions.
          </p>
          <BlueButton
            style={{ maxWidth: 320 }}
            onClick={handleCtaClick}
            title="Book a consultation - It's free"
          />
        </div>
      </div>
    </section>
  );
};

export default withRouter(ValueProps);
