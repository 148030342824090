import React, { Component } from "react";
import Context from "./Context";

class Provider extends Component {
  state = {
    addressInfo: {
      fullAddress: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      lat: 0,
      lng: 0,
    },
    sellingValue: "",
    knowAboutfelixCommission: "",
    propertyType: "",
    propertyAge: "",
    propertyCondition: "",
    additionalDetails: "",
    recipient: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    },
    currentStep: 0,
    leadSource: "felix_hvr",
    valuations: {
      low: 246153,
      value: 256410,
      high: 266666,
    },
  };

  saveStateToSessionStorage = () => {
    sessionStorage.setItem("values", JSON.stringify(this.state));
  };

  componentDidUpdate(preProps, prevState) {
    if (prevState !== this.state) this.saveStateToSessionStorage();
  }

  componentDidMount() {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        const values = JSON.parse(sessionStorage.getItem("values"));
        if (values) this.setState({ ...values });
      }
    }
  }

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          updateAddress: (addressInfo) => {
            this.setState({
              addressInfo,
            });
          },
          updatePropertyType: (propertyType) =>
            this.setState({
              propertyType,
            }),
          updatePropertyAge: (propertyAge) =>
            this.setState({
              propertyAge,
            }),
          updatePropertyCondition: (propertyCondition) =>
            this.setState({
              propertyCondition,
            }),

          updateSellingValue: (sellingValue) =>
            this.setState({
              sellingValue,
            }),

          updateKnowAboutfelixCommission: (knowAboutfelixCommission) =>
            this.setState({
              knowAboutfelixCommission,
            }),
          updateReAgent: (reAgent) =>
            this.setState({
              reAgent,
            }),

          updateAdditionalDetails: (additionalDetails) =>
            this.setState({
              additionalDetails,
            }),
          updateRecipient: (recipient) => {
            this.setState({
              recipient,
            });
          },
          updateCurrentStep: (currentStep) =>
            this.setState({
              currentStep,
            }),
          updateValuations: (valuations) =>
            this.setState({
              valuations,
            }),
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Provider;
