import React, { useState, useContext, useEffect } from "react";
import FormHeader from "../FormHeader";
import FormInputContainer from "../FormInputContainer";
import BackButton from "../../common/BackButton";
import Context from "../../../Context";
import Stepper from "../Stepper";
import { baseUrl } from "../../../utils/baseUrl";
import { Link } from "react-router-dom";
import SearchInput from "../../common/SearchInput/SearchInput";
import searchIconBlue from "../../../assets/search-icon-blue.svg";
import axios from "axios";

const EnterAddressSuggest = (props) => {
  const [address, setAddress] = useState("");
  const [suggestions, setSuggesions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const context = useContext(Context);

  const handleGetEstimate = () => {
    props.history.push("/form/address/1");
  };

  const getAddressSuggestions = (addressInput) => {
    const params = {
      addressInput,
    };

    axios
      .get(`${baseUrl}/api/v1/address/suggestions`, { params })
      .then((res) => {
        setDropdownOpen(true);
        setSuggesions(res.data);
      });
  };

  const handleAddressInput = (e) => {
    setAddress(e.target.value);
    getAddressSuggestions(e.target.value);
  };

  const handleSuggestionSelection = (suggestion) => {
    setDropdownOpen(false);
    setAddress(suggestion.address);
    handleGetPropertyDetails(suggestion.id);
  };

  const handleGetPropertyDetails = (id) => {
    const params = {
      id,
    };
    axios.get(`${baseUrl}/api/v1/address/details`, { params }).then((res) => {
      context.updateAddress({ ...res.data, address2: "" });
    });
  };

  const createSuggestions = (suggestions) =>
    suggestions.map((suggestion) => (
      <li
        key={suggestion.id}
        onClick={() => handleSuggestionSelection(suggestion)}
      >
        {suggestion.address}
      </li>
    ));

  useEffect(() => {
    if (context.addressInfo.fullAddress)
      setAddress(context.addressInfo.fullAddress);
  }, [context.addressInfo]);

  return (
    <Context.Consumer>
      {({ addressInfo }) => (
        <>
          <BackButton />
          <Stepper />
          <FormHeader text="Enter your address" />
          <FormInputContainer>
            <div className="enter-address">
              <SearchInput
                titles={["Search\u00A0homes", "Get\u00A0estimate"]}
                placeholder="Enter your home address"
                buttonClassName="mint"
                btnTitle="Get estimate"
                icon={searchIconBlue}
                onChange={handleAddressInput}
                value={address}
                btnOnClick={handleGetEstimate}
                btnDisabled={!addressInfo}
              />
              {dropdownOpen && (
                <div className="dropdown">
                  <ul>{createSuggestions(suggestions)}</ul>
                </div>
              )}
            </div>
          </FormInputContainer>
        </>
      )}
    </Context.Consumer>
  );
};

export default EnterAddressSuggest;
