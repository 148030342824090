import React, { useEffect, useState } from "react";
import GoogleMap from "google-map-react";
import NumberFormat from "react-number-format";
import felixMarker from "../../../assets/felix-marker.svg";
import "./Comps.scss";

const Map = ({ lat, lng, comps, selected }) => {
  const [markers, setMarkers] = useState([]);

  const createMarkers = () => {
    const newMarkers =
      comps?.map(({ mlsId, geo, salePrice }) => (
        <span
          key={mlsId}
          lat={geo.lat}
          lng={geo.lng}
          className={`comp-pin ${mlsId === selected ? "selected-pin" : null}`}
        >
          <NumberFormat
            value={salePrice}
            prefix="$"
            displayType="text"
            thousandSeparator
          />
        </span>
      )) || [];
    setMarkers(newMarkers);
  };

  const subjectPropMarker = (
    <img key={1} src={felixMarker} lat={lat} lng={lng} />
  );

  const createMapOptions = () => {
    return {
      zoomControl: false,
      fullscreenControl: false,
    };
  };

  useEffect(() => {
    createMarkers();
  }, [selected, comps]);

  const center = [lat, lng];
  return (
    <GoogleMap
      options={createMapOptions}
      bootstrapURLKeys={{
        key: "AIzaSyCmlIj5snp4jbVlm7wedy8i-IPLM1NXvlQ",
        language: "en",
      }}
      center={center}
      zoom={14}
    >
      {[...markers, subjectPropMarker]}
    </GoogleMap>
  );
};

export default Map;
