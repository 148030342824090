import React from "react";
import "./Hero.scss";

import NumberFormat from "react-number-format";
import house from "../../../assets/valuation_hero_house.svg";
import house2 from "../../../assets/valuation_hero_house_2.svg";
import BlueButton from "../../common/BlueButton";
import isMobile from "../../../utils/isMobile";
import { withRouter } from "react-router";
import { Slider } from "@material-ui/core";

const valuetext = (value) => {
  return (
    <NumberFormat
      className="value-text"
      decimalScale={0}
      fixedDecimalScale
      value={value}
      prefix="$"
      displayType="text"
      thousandSeparator
    />
  );
};

const Hero = (props) => {
  const { low, high } = props.valuations || {};
  const { addressInfo } = props;
  const lat = addressInfo ? addressInfo.lat : 36.163738614114024;
  const lng = addressInfo ? addressInfo.lng : -86.8059630493164;

  const hasValues = low && high;

  const handleCtaClick = () => {
    props.history.push("/consultation");
  };

  const marks = [
    {
      value: low,
      label: "Low",
    },
    {
      value: high,
      label: "High",
    },
  ];

  const ValuationContent = () => (
    <>
      <div className="container">
        <Slider
          value={[low, high]}
          min={low * 0.98}
          step={100}
          max={high * 1.02}
          valueLabelDisplay="on"
          aria-labelledby="range-slider"
          valueLabelFormat={valuetext}
          marks={marks}
        />
      </div>

      <div className="bottom-block-wrapper">
        <h3>
          How accurate are{" "}
          <br style={{ display: isMobile() ? "block" : "none" }} />
          these values?
        </h3>
        <p>
          These are computer generated values. To get the most accurate idea of
          what a buyer would pay in today’s market, talk to an expert today.
        </p>

        <div className="savings">
          <h2>When you sell with Felix, you’ll save:</h2>
          <span className="savings-amounts">
            {valuetext(low * 0.02)} - {valuetext(high * 0.02)}
          </span>
        </div>
        <BlueButton
          onClick={handleCtaClick}
          title="Book a consultation - It's free"
        />
        <img src={house} alt={"house"} className={"hero-house"} />
        <img
          src={house2}
          alt={"house2"}
          className={"hero-house second-house"}
        />
      </div>
    </>
  );

  return (
    <section id="valuation" className="estimates">
      <div className="top-block-wrapper">
        <h1>
          Home Value <br className="hide-on-desktop" />
          Estimate Ranges
        </h1>
        <p>
          {addressInfo.address}, {addressInfo.city}, {addressInfo.state}
          {addressInfo.zip}
        </p>
        <img
          className="satellite-map"
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=20&size=1440x800&maptype=satellite&key=AIzaSyCmlIj5snp4jbVlm7wedy8i-IPLM1NXvlQ`}
        />
      </div>
      {hasValues ? (
        <ValuationContent />
      ) : (
        <div className="not-found-valuation-container">
          <div className="not-found">
            <p>
              Unfortunately we couldn't find any good comps in your
              neighborhood.
            </p>
            <p>
              No worries! Just set up a consultation and we'll do this one by
              hand 🕵️‍♂️
            </p>
          </div>
          <BlueButton
            onClick={handleCtaClick}
            title="Book a consultation - It's free"
          />
        </div>
      )}
    </section>
  );
};

export default withRouter(Hero);
