import React from "react";
import "./SellingTable.scss";
import CheckIcon from "../../../assets/check-icon.svg";
import UncheckIcon from "../../../assets/uncheck-icon.svg";

const SellingTableDesktop = () => {
  return (
    <div id={"pricing-sell-wrapper"}>
      <div className={"advantage-table"}>
        <div className={"row"} style={{ borderColor: "#FFD463" }}>
          <div style={{ flex: 50 }}>
            <h2>Selling with Felix</h2>
          </div>
          <div className={"felix-column table-header"}>FELIX</div>
          <div className={"traditional-column table-header"}>TRADITIONAL</div>
          <div className={"empty"} />
        </div>

        <div className={"row"}>
          <div className={"name-column"}>1% listing commission</div>
          <div className={"felix-column"}>
            <img src={CheckIcon} alt={"check-icon"} />
          </div>
          <div className={"traditional-column"}>
            <img src={UncheckIcon} alt={"uncheck-icon"} />
          </div>
          <div className={"empty"} />
        </div>
        <div className={"row"}>
          <div className={"name-column"}>No hidden fees</div>
          <div className={"felix-column"}>
            <img src={CheckIcon} alt={"check-icon"} />
          </div>
          <div className={"traditional-column"}>
            <img src={UncheckIcon} alt={"uncheck-icon"} />
          </div>
          <div className={"empty"} />
        </div>
        <div className={"row"}>
          <div className={"name-column"}> Custom home valuation analysis</div>
          <div className={"felix-column"}>
            <img src={CheckIcon} alt={"check-icon"} />
          </div>
          <div className={"traditional-column"}>
            <img src={UncheckIcon} alt={"uncheck-icon"} />
          </div>
          <div className={"empty"} />
        </div>
        <div className={"row"}>
          <div className={"name-column"}>
            Comprehensive home marketing strategy
          </div>
          <div className={"felix-column"}>
            <img src={CheckIcon} alt={"check-icon"} />
          </div>
          <div className={"traditional-column"}>
            <img src={UncheckIcon} alt={"uncheck-icon"} />
          </div>
          <div className={"empty"} />
        </div>
        <div className={"row"}>
          <div className={"name-column"}>
            {" "}
            Professional phototography + drone footage
          </div>
          <div className={"felix-column"}>
            <img src={CheckIcon} alt={"check-icon"} />
          </div>
          <div className={"traditional-column"}>
            <img src={UncheckIcon} alt={"uncheck-icon"} />
          </div>
          <div className={"empty"} />
        </div>
      </div>
    </div>
  );
};

export default SellingTableDesktop;
