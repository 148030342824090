import React, { useEffect, useContext, useState } from "react";
import { withRouter } from "react-router";
import "./LeadModal.scss";
import { Formik } from "formik";
import Context from "../../../Context";
import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import Modal from "./Modal/Modal";
import House from "../../../assets/auth-modal-house-bg.png";
import PhoneInput from "../../common/PhoneInput";
import Delay from "react-delay";
import TextInput from "../../common/TextInput/TextInput";
import BlueButton from "../../common/BlueButton";

const RecipientSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Must have at least 2 characters")
    .max(30, "Must have at most 30 characters")
    .required("First name is required"),
  lastName: Yup.string()
    .min(2, "Must have at least 2 characters")
    .max(30, "Must have at most 30 characters"),
  phone: Yup.string().test(
    "is-phone",
    "Must be a valid phone number",
    (value) => isValidPhoneNumber(`${value}`)
  ),
  email: Yup.string().email().required("Email is required"),
});

const RecipientModal = (props) => {
  const context = useContext(Context);
  const [recipient, setRecipient] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  });

  const handleSubmit = (recipientInfo) => {
    context.updateRecipient(recipientInfo);
    setTimeout(() => {
      props.history.push("/report/valuation");
    }, 500);
  };

  useEffect(() => {
    if (context.recipient.name) {
      setRecipient(context.recipient);
    }
  }, [context]);

  return (
    <Context.Consumer>
      {() => (
        <div id="recipient-modal">
          <div id="recipient-blur" />
          <Delay wait={1500}>
            <Modal open={true} {...props}>
              <div className={"sign-up-modal-wrapper"}>
                <div className={"left"}>
                  <img src={House} alt={"auth-house"} />
                </div>
                <div className={"right"}>
                  <Formik
                    initialValues={recipient}
                    validationSchema={RecipientSchema}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                  >
                    {({
                      errors,
                      values,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                    }) => {
                      const handlePhoneChange = (phone) => {
                        setFieldValue("phone", phone);
                      };
                      return (
                        <form
                          noValidate
                          autoComplete="off"
                          onSubmit={handleSubmit}
                        >
                          <h2>Where should we send your report?</h2>
                          <TextInput
                            onChange={handleChange}
                            name="firstName"
                            placeholder="Name"
                            error={errors.firstName}
                            style={{
                              marginBottom: 25,
                            }}
                          />
                          <TextInput
                            onChange={handleChange}
                            placeholder="Last Name"
                            name="lastName"
                            error={errors.lastName}
                            style={{
                              marginBottom: 25,
                            }}
                          />
                          <TextInput
                            onChange={handleChange}
                            placeholder="Email"
                            name="email"
                            error={errors.email}
                            style={{
                              marginBottom: 25,
                            }}
                          />
                          <PhoneInput
                            name="phone"
                            placeholder="Phone"
                            error={errors.phone}
                            onChange={handlePhoneChange}
                          />

                          <BlueButton
                            type="submit"
                            title={"Submit"}
                            style={{ maxWidth: 488, marginTop: 30 }}
                          />
                        </form>
                      );
                    }}
                  </Formik>
                  <div className={"house-bg-mobile"}>
                    <img src={House} alt={"auth-house"} />
                    <div className={"bg-mobile"} />
                  </div>
                </div>
              </div>
            </Modal>
            );
          </Delay>
        </div>
      )}
    </Context.Consumer>
  );
};

export default withRouter(RecipientModal);
