import React from "react";
import { withRouter } from "react-router";
import "./HomeValue.scss";
import LeadModal from "./LeadModal";

const Recipient = () => {
  return (
    <div id="valuations-content">
      <LeadModal />
    </div>
  );
};

export default withRouter(Recipient);
