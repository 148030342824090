import React from "react";
import {withRouter} from "react-router";
import "./Navbar.scss";
import logoBlack from "../../../assets/new_logo_black.svg";
import phoneIcon from "../../../assets/phone_icon.svg";
import isMobile from "../../../utils/isMobile";

const Navbar = () => {
  return (
    <div className="navbar navbar-transparent">
      <div className="container navbar-container">
        <a href="https://felixhomes.com/">
          <img className="logo" src={logoBlack} alt="logo-black" />
        </a>
      </div>
    </div>
  );
};

export default withRouter(Navbar);
