// function - create cookie
const createCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
};
// function - read cookie
const readCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
// function - erase cookie
const eraseCookie = (name) => {
  createCookie(name, "", -1);
};

exports.buildLeadSourceObj = () => {
  const leadSourceObj = {
    gclid: readCookie("gclid"),
    fbclid: readCookie("fbclid"),
    msclkid: readCookie("msclkid"),
    usource: readCookie("usrc"),
    umedium: readCookie("umed"),
    ucampaign: readCookie("ucmp"),
    uterm: readCookie("utrm"),
    ucontent: readCookie("ucnt"),
  };

  return leadSourceObj;
};

exports.dropCookies = () => {
  // retrieve utm parameters from url
  let url_string = window.location.href;
  let url = new URL(url_string);
  let usource = url.searchParams.get("utm_source");
  let umedium = url.searchParams.get("utm_medium");
  let ucampaign = url.searchParams.get("utm_campaign");
  let uterm = url.searchParams.get("utm_term");
  let ucontent = url.searchParams.get("utm_content");
  let gclid = url.searchParams.get("gclid");
  let fbclid = url.searchParams.get("fbclid");
  let msclkid = url.searchParams.get("msclkid");
  // if cookies enabled, update them with new utms. If no utms were supplied, try to get them from the cookie
  if (navigator.cookieEnabled) {
    if (gclid) {
      createCookie("gclid", gclid, 30);
    } else {
      gclid = readCookie("gclid");
    }

    if (fbclid) {
      createCookie("fbclid", fbclid, 30);
    } else {
      fbclid = readCookie("fbclid");
    }

    if (msclkid) {
      createCookie("msclkid", msclkid, 30);
    } else {
      msclkid = readCookie("msclkid");
    }

    if (usource) {
      createCookie("usrc", usource, 30);
    } else {
      usource = readCookie("usrc");
    }

    if (umedium) {
      createCookie("umed", umedium, 30);
    } else {
      umedium = readCookie("umed");
    }

    if (ucampaign) {
      createCookie("ucmp", ucampaign, 30);
    } else {
      ucampaign = readCookie("ucmp");
    }

    if (uterm) {
      createCookie("utrm", uterm, 30);
    } else {
      uterm = readCookie("utrm");
    }

    if (ucontent) {
      createCookie("ucnt", ucontent, 30);
    } else {
      ucontent = readCookie("ucnt");
    }
  }
};
