import React, { useState, useContext, useEffect } from "react";

import Hero from "./Hero";
import WhatYouGet from "./WhatYouGet";
import Benefits from "./Benefits/index";
import HomeIsWorth from "./HomeIsWorth";
import ScheduleCall from "./ScheduleCall/index";
import Footer from "../Footer";
import isMobile from "../../utils/isMobile";
import "./Landing.scss";

const Landing = () => {
  const Sectionheader = ({ top, bottom }) => (
    <div className="section-header">
      {top}
      {bottom}
    </div>
  );

  return (
    <>
      <Hero />

      <WhatYouGet />

      <Benefits />

      <HomeIsWorth
        header={
          <Sectionheader
            bottom={<h4>Find out how much your home is worth.</h4>}
          />
        }
      />

      <Footer />
    </>
  );
};

export default Landing;
