import React from "react";
import "./Questions.scss";
import BlueButton from "../../common/BlueButton";
import { withRouter } from "react-router";

const Questions = (props) => {
  const handleCtaClick = () => {
    props.history.push("/consultation");
  };

  return (
    <div id="questions">
      <h2>Questions?</h2>
      <p>
        Questions or comments?
        <br /> Don't hesitate to reach out:
      </p>
      <BlueButton
        style={{ maxWidth: 320 }}
        onClick={handleCtaClick}
        title="Book a consultation - It's free"
      />
    </div>
  );
};

export default withRouter(Questions);
