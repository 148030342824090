import React from "react";
import "./FormFooter.scss";

const FormFooter = () => {
  return (
    <div id="form-footer">
      <p>
        FLX Operating LLC DBA Felix Homes License #264398 | Copyright 2020.
        <br /> All Rights Reserved | Privacy Policy | Terms
      </p>
    </div>
  );
};

export default FormFooter;
