import React, { useContext } from "react";
import { withRouter } from "react-router";
import "./PropertyInfo.scss";

import BackButton from "../../common/BackButton";
import OptionButton from "../../common/OptionButton";
import FormInputContainer from "../FormInputContainer";
import Context from "../../../Context";
import FormHeader from "../FormHeader";
import Stepper from "../Stepper";
import FormWrapper from "../FormWrapper";

const HomeCondition = (props) => {
  const context = useContext(Context);

  const handleOptionSelection = (propertyAge) => {
    context.updatePropertyCondition(propertyAge);
    props.history.push("/form/selling/1");
  };

  return (
    <Context.Consumer>
      {({ propertyCondition }) => (
        <>
          <BackButton />
          <Stepper />
          <FormHeader text="What's the condition of your home?" />
          <FormWrapper>
            <OptionButton
              option="Needs nothing"
              onClick={handleOptionSelection}
              selected={propertyCondition}
            />
            <OptionButton
              option="Needs significant work"
              onClick={handleOptionSelection}
              selected={propertyCondition}
            />
            <OptionButton
              option="Needs a little work"
              onClick={handleOptionSelection}
              selected={propertyCondition}
            />
            <OptionButton
              option="Tear down"
              onClick={handleOptionSelection}
              selected={propertyCondition}
            />
          </FormWrapper>
        </>
      )}
    </Context.Consumer>
  );
};

export default withRouter(HomeCondition);
