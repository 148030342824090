import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router";
import Context from "../../../Context";
import "./Stepper.scss";

const Stepper = (props) => {
  const context = useContext(Context);
  const location = useLocation();

  useEffect(() => {
    let step;
    const newPosition = location.pathname.split("/")[2];

    if (newPosition === "address") step = 1;
    if (newPosition === "property-info") step = 2;
    if (newPosition === "selling") step = 3;
    if (newPosition === "additional-details") step = 4;

    if (context.currentStep !== step) {
      context.updateCurrentStep(step);
    }
  }, []);

  const checkActive = (currentStep, step) =>
    currentStep === step ? "active" : "";

  const checkComplete = (currentStep, step) =>
    step < currentStep ? "completed" : "";

  return (
    <div id="stepper">
      <div className="num-container">
        <span
          className={`step 
        ${checkActive(context.currentStep, 1)} 
        ${checkComplete(context.currentStep, 1)} `}
        >
          1
        </span>
        <span className="step-desc">Confirm your address</span>
      </div>

      <span className={`line ${checkComplete(context.currentStep, 1)} `} />
      <div className="num-container">
        <span
          className={`step 
         ${checkActive(context.currentStep, 2)} 
         ${checkComplete(context.currentStep, 2)}`}
        >
          2
        </span>
        <span className="step-desc">Property information</span>
      </div>

      <span className={`line ${checkComplete(context.currentStep, 2)}`} />

      <div className="num-container">
        <span
          className={`step 
         ${checkActive(context.currentStep, 3)} 
         ${checkComplete(context.currentStep, 3)}`}
        >
          3
        </span>
        <span className="step-desc">Looking to sell?</span>
      </div>

      <span className={`line ${checkComplete(context.currentStep, 3)}`} />

      <div className="num-container">
        <span
          className={`step ${checkActive(context.currentStep, 4)} 
        ${checkComplete(context.currentStep, 4)}`}
        >
          4
        </span>
        <span className="step-desc">Additional details</span>
      </div>
    </div>
  );
};

export default Stepper;
