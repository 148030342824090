import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#4C88F8",
    },
  },
  typography: {
    fontFamily: '"CircularStd", "Helvetica", "Arial", sans-serif',
    fontSize: 12,
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 500,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#DDDDDD",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#DDDDDD",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "#DDDDDD",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "#4C88F8",
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#4C88F8",
        },
      },
    },
    MuiGrid: {
      root: { position: "relative" },
    },

    PrivateValueLabel: {
      offset: {
        top: -45,
        transform: "scale(1) translateY(-26px) !important",
        "@media (max-width: 768px)": {
          top: -20,
        },
      },

      label: {
        display: "flex",
        backgroundColor: "#4C88F8",
        padding: "2px 15px",
        transform: "rotate(45deg)",
        "@media (max-width: 768px)": {
          padding: "7px 5px",
        },
      },
      circle: {
        height: 50,
        width: 50,
        backgroundColor: "#4C88F8",
        fontSize: 40,
        fontFamily: '"CircularStd", "Helvetica", "Arial", sans-serif',
        fontWeight: "bold",
        "@media (max-width: 768px)": {
          fontSize: 18,
          height: 32,
          width: 32,
        },
      },
    },
    MuiSlider: {
      markLabel: {
        fontSize: 20,
        fontFamily: '"CircularStd", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
      },
    },
    MuiSlider: {
      rail: {
        height: 5,
        borderRadius: 10,
      },
      valueLabel: {
        left: "calc(-50% - 9px)",
        "@media (max-width: 768px)": {
          left: "calc(-50% - 1px)",
        },
      },
      markLabel: {
        fontSize: 20,
        fontWeight: 500,
        color: "#072967",
        marginTop: 5,
        "@media (max-width: 768px)": {
          fontSize: 16,
          marginTop: 0,
        },
      },
      track: {
        height: 5,
      },
      thumb: {
        width: 17,
        height: 17,
        marginTop: -6,
      },
    },
  },
});
