import React from "react";
import "./Footer.scss";
import fbIcon from "../../assets/fb-icon.svg";
import inIcon from "../../assets/in-icon.svg";
import instIcon from "../../assets/inst-icon.svg";
import youtubeIcon from "../../assets/youtube-icon.svg";

const Footer = () => {
  return (
    <div className={"footer-wrapper max-width"}>
      <div className={"top"}>
        <div className={"left"}>
          <div className={"column-wrapper"}></div>
          <div className={"column-wrapper"}></div>
          <div className={"column-wrapper"}></div>
          <div className={"column-wrapper"}>
            <a href={"/"}>
              2550 Meridian Boulevard,
              <br />
              Franklin, TN 37067
            </a>
            <div className={"socials-mobile"}>
              <Socials />
            </div>
          </div>
        </div>
        <div className={"right"}>
          <Socials />
        </div>
      </div>
      <div className={"bottom"}>
        <div className={"border-top"} />
        <div className={"left"}>
          <span>© Felix Homes 2020</span>
        </div>
        <div className={"right"}>
          <div className={"policy-terms"}></div>
        </div>
      </div>
      <div className={"bottom-mobile"}>
        <span>© Felix Homes 2020</span>
        <div className={"policy-terms"}>
          <a href={"/"}>Privacy Policy</a>
          <a href={"/"}>Terms</a>
        </div>
      </div>
    </div>
  );
};

const Socials = () => (
  <div className={"socials"}>
    <a
      href={"https://www.instagram.com/felixhomesrealty/"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={instIcon} alt={"icon"} />
    </a>
    <a
      href={"https://www.facebook.com/FelixHomesRealty/"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={fbIcon} alt={"icon"} />
    </a>
    <a
      href={"https://www.linkedin.com/company/felixhomes/"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={inIcon} alt={"icon"} />
    </a>
    <a
      href={"https://www.youtube.com/channel/UC1KvMk-Vp9J9_2jY0fApJvg"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={youtubeIcon} alt={"icon"} />
    </a>
  </div>
);

export default Footer;
