import React from "react";
import NumberFormat from "react-number-format";
import "./Comps.scss";

const Comp = ({ comp, setSelected }) => {
  return (
    <li className="comp" onMouseEnter={() => setSelected(comp.mlsId)}>
      {comp.photos[0] && <img src={`${comp.photos[0]}?width=200`} />}

      <div className="details">
        <NumberFormat
          className="sale-price"
          value={comp.salePrice}
          prefix="$"
          displayType="text"
          thousandSeparator
        />
        <div className="address">{comp.address.full}</div>
        <span className="detail">{comp.property.bedrooms} Bd</span>
        <span className="detail-divider" />
        <span className="detail">{comp.property.bathRooms} Bd</span>
        <span className="detail-divider" />
        <NumberFormat
          className="detail"
          value={comp.property.area}
          suffix="sf"
          displayType="text"
          thousandSeparator
        />
      </div>
    </li>
  );
};

export default Comp;
