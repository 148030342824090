import React, {useEffect, useState} from "react";
import "./SocialProofWidget.scss";
import socialProofIcon from "../../assets/social-proof-icon.png";
import persons from "./persons";

const SocialProofWidget = () => {
  const [person, setPerson] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let personAnimation = setInterval(() => {
      const randomIdx = Math.floor(Math.random() * persons.length) + 0;

      setPerson(persons[randomIdx]);
      setIsOpen(true);
      let closeSocialProof = setInterval(() => {
        setIsOpen(false);

        clearInterval(closeSocialProof);
      }, 2500);
    }, 7500);

    return () => {
      clearInterval(personAnimation);
    };
  }, []);

  return (
    <div id="social-proof-widget" className={`${isOpen ? "active" : "closed"}`}>
      <img src={socialProofIcon} />
      <div className="social-proof-details">
        <div>
          <div className="social-proof-entity">
            <span>{person?.name}</span> from&nbsp;
            <span>{person?.city}</span>&nbsp;
          </div>
          <div className="social-proof-copy">
            just received a home valuation
          </div>
        </div>

        <div className="social-proof-time">
          {person?.hoursAgo} hour
          {person?.hoursAgo > 1 ? "s" : ""} ago
        </div>
      </div>
    </div>
  );
};

export default SocialProofWidget;
