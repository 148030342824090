import React from "react";
import "./common.scss";

// import { css, keyframes, injectGlobal } from "emotion/macro";

import { ScaleLoader } from "react-spinners";

const Loader = props => {
  return (
    <div className="loader">
      <ScaleLoader
        // css={override}
        size={250}
        //size={"150px"} this also works
        color={"#123abc"}
        loading={true}
      />
    </div>
  );
};

export default Loader;
